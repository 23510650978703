import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _this = this;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      selfUpdate: true,
      form: _vm.createForm,
      layout: "horizontal",
      labelCol: {
        style: "min-width: 140px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "Category"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentcategoryid", {
        rules: [{
          required: true,
          message: "请选择一级分类"
        }]
      }],
      expression: "[\n                        'parentcategoryid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择一级分类',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "show-search": "",
      size: "large",
      placeholder: "请选择一级分类",
      disabled: _vm.isFormDisabled(),
      "filter-option": _vm.filterOption
    },
    on: {
      change: _vm.handleFirstChange
    }
  }, _vm._l(_vm.seriesCateList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryid", {
        rules: [{
          required: true,
          message: "请选择二级分类"
        }]
      }],
      expression: "[\n                        'categoryid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择二级分类',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px",
      "margin-left": "20px"
    },
    attrs: {
      "show-search": "",
      placeholder: "请选择二级分类",
      size: "large",
      "filter-option": _vm.filterOption,
      disabled: _vm.isFormDisabled() || !_vm.secondSeriesCateList.length
    },
    on: {
      change: function change($event) {
        return _vm.handleSelectChange("categoryid");
      }
    }
  }, _vm._l(_vm.secondSeriesCateList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "Brand"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brandid", {
        rules: [{
          required: true,
          message: "请选择品牌"
        }]
      }],
      expression: "[\n                        'brandid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择品牌',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "show-search": "",
      size: "large",
      "filter-option": _vm.filterOption,
      disabled: _vm.isFormDisabled(),
      placeholder: "请选择品牌"
    },
    on: {
      change: function change($event) {
        return _vm.handleSelectChange("brandid");
      }
    }
  }, _vm._l(_vm.brandidList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "Style No."
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sku", {
        rules: [{
          required: _vm.skurequired("sku"),
          message: "请输入主货号"
        }, {
          pattern: /^[^\u4e00-\u9fa5]+$/,
          message: "主货号不可输入中文",
          trigger: ["blur", "change"]
        }]
      }],
      expression: "[\n                        'sku',\n                        {\n                            rules: [\n                                {\n                                    required: skurequired('sku'),\n                                    message: '\u8BF7\u8F93\u5165\u4E3B\u8D27\u53F7',\n                                },\n                                {\n                                    pattern: /^[^\\u4e00-\\u9fa5]+$/,\n                                    message: '\u4E3B\u8D27\u53F7\u4E0D\u53EF\u8F93\u5165\u4E2D\u6587',\n                                    trigger: ['blur', 'change'],\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: !_vm.skurequired("sku") || _vm.isFormDisabled(),
      autocomplete: "off",
      size: "large",
      placeholder: "请输入"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        _vm.handleSkuChange(e);
      }
    }
  }), _c("a-checkbox", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      disabled: _vm.isFormDisabled()
    },
    on: {
      change: _vm.onskuChange
    },
    model: {
      value: _vm.notSku,
      callback: function callback($$v) {
        _vm.notSku = $$v;
      },
      expression: "notSku"
    }
  }, [_vm._v("暂无货号")])], 1), _c("a-form-item", {
    attrs: {
      label: "辅助货号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["auxiliarysku", {
        rules: [{
          pattern: /^[^\u4e00-\u9fa5]+$/,
          message: "辅助货号不可输入中文",
          trigger: ["blur", "change"]
        }]
      }],
      expression: "[\n                        'auxiliarysku',\n                        {\n                            rules: [\n                                {\n                                    pattern: /^[^\\u4e00-\\u9fa5]+$/,\n                                    message: '\u8F85\u52A9\u8D27\u53F7\u4E0D\u53EF\u8F93\u5165\u4E2D\u6587',\n                                    trigger: ['blur', 'change'],\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: _vm.isFormDisabled(),
      size: "large",
      placeholder: "请输入",
      autocomplete: "off"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "Item Name"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入名称"
        }]
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                { required: true, message: '请输入名称' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autocomplete: "off",
      disabled: _vm.isFormDisabled(),
      size: "large",
      "max-length": 200,
      rows: 4,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "Release Date"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saletime", {
        rules: [{
          required: false,
          message: "请选择日期"
        }]
      }],
      expression: "[\n                        'saletime',\n                        {\n                            rules: [\n                                {\n                                    required: false,\n                                    message: '请选择日期',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      format: "YYYY-MM-DD",
      locale: "zh-cn"
    }
  })], 1), _c("a-form-item", {
    staticClass: "search_item_long",
    attrs: {
      label: "Retail Price"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleprice", {
        rules: [{
          required: false,
          message: "请输入发售价"
        }]
      }],
      expression: "[\n                        'saleprice',\n                        {\n                            rules: [\n                                {\n                                    required: false,\n                                    message: '请输入发售价',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      autocomplete: "off",
      min: 0,
      max: 999999999,
      placeholder: "请输入数字或小数点"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "商品图片"
    }
  }, [_c("upload-image", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      showTips: _vm.addType != 1,
      imgProportion: _vm.imgProportion,
      fileLists: _vm.fileList,
      memorySize: "500kb",
      multiple: 99,
      img: _vm.showContent
    },
    on: {
      getImageId: _vm.getDelImageId,
      delimageId: _vm.delDelimageId,
      draggable: _vm.handleDraggable,
      previewImg: _vm.previewImg
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "Size"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sextype", {
        rules: [{
          required: true,
          message: "请选择尺码规格"
        }]
      }],
      expression: "[\n                        'sextype',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择尺码规格',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px",
      "margin-bottom": "20px"
    },
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      placeholder: "请选择尺码规格"
    },
    on: {
      change: function change($event) {
        return _vm.handleSelectChange("sextype");
      }
    }
  }, _vm._l(_vm.sexSizeType, function (item, idx) {
    return _c("a-select-option", {
      key: idx
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _vm.showSizeType == 1 ? _c("a-table", {
    staticClass: "tableone",
    staticStyle: {
      width: "574px"
    },
    attrs: {
      bordered: true,
      pagination: false,
      rowKey: function rowKey(it, i) {
        return i;
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        fixed: true,
        getCheckboxProps: function getCheckboxProps() {
          return {
            props: {
              disabled: _this.addType === "1"
            }
          };
        }
      },
      columns: _vm.columns,
      "data-source": _vm.shoesTableData
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [_c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }) : _vm._e(), _c("br"), _vm.showSizeType == 2 ? _c("div", [_c("div", {
    style: {
      borderBottom: "1px solid #E9E9E9"
    }
  }, [_c("a-checkbox", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      indeterminate: _vm.indeterminate,
      checked: _vm.checkAll
    },
    on: {
      change: _vm.onCheckAllChange
    }
  }, [_vm._v("全选")])], 1), _c("a-checkbox-group", {
    attrs: {
      disabled: _vm.isFormDisabled(),
      options: _vm.plainOptions
    },
    on: {
      change: _vm.onDressChange
    },
    model: {
      value: _vm.checkedList,
      callback: function callback($$v) {
        _vm.checkedList = $$v;
      },
      expression: "checkedList"
    }
  })], 1) : _vm._e(), _vm.showSizeType == 0 ? _c("span", [_vm._l(_vm.seriesList, function (item, index) {
    return _c("span", [_c("a-input", {
      staticClass: "s_title",
      attrs: {
        disabled: _vm.isFormDisabled(),
        autocomplete: "off",
        size: "large",
        "max-length": 20,
        placeholder: "请添加尺码信息"
      },
      model: {
        value: item.size,
        callback: function callback($$v) {
          _vm.$set(item, "size", $$v);
        },
        expression: "item.size"
      }
    }), index != 0 && index == _vm.seriesList.length - 1 ? _c("span", {
      staticClass: "item_del",
      on: {
        click: function click($event) {
          return _vm.handleDeleteSize(item, index);
        }
      }
    }, [_vm._v("删除")]) : _vm._e(), index == _vm.seriesList.length - 1 ? _c("span", {
      staticClass: "item_del",
      on: {
        click: function click($event) {
          return _vm.handleSeriesAdd();
        }
      }
    }, [_vm._v("添加")]) : _vm._e(), _c("br")], 1);
  }), _c("span", {
    staticClass: "size_desc"
  }, [_vm._v("注：尺码添加的格式为属性加属性值加单位，例如小号15cm")])], 2) : _vm._e()], 1), _c("a-form-item", {
    attrs: {
      label: "Product Information"
    }
  }, [_c("a-table", {
    staticClass: "tabletwo",
    staticStyle: {
      width: "574px"
    },
    attrs: {
      rowKey: function rowKey(it, i) {
        return i;
      },
      bordered: true,
      pagination: false,
      columns: _vm.columnsgood,
      "data-source": _vm.datagood,
      align: "left"
    },
    scopedSlots: _vm._u([_vm._l(_vm.columnsgood, function (item, index) {
      return {
        key: item.key,
        fn: function fn(text, record, index) {
          return [item.key == "name" ? _c("span", [_c("a-input", {
            staticStyle: {
              width: "136px",
              border: "none",
              outline: "medium"
            },
            attrs: {
              disabled: _vm.isFormDisabled(),
              placeholder: "请输入名称",
              autocomplete: "off"
            },
            on: {
              change: _vm.handleChange
            },
            model: {
              value: record[item.key],
              callback: function callback($$v) {
                _vm.$set(record, item.key, $$v);
              },
              expression: "record[item.key]"
            }
          })], 1) : item.key == "content" ? _c("span", [_c("span", {
            staticClass: "dele"
          }, [_c("a-input", {
            staticStyle: {
              display: "inline-block",
              width: "430px",
              border: "none",
              outline: "medium"
            },
            attrs: {
              disabled: _vm.isFormDisabled(),
              placeholder: "请输入内容",
              autocomplete: "off"
            },
            on: {
              change: _vm.handleChangecontent
            },
            model: {
              value: record[item.key],
              callback: function callback($$v) {
                _vm.$set(record, item.key, $$v);
              },
              expression: "record[item.key]"
            }
          }), index == 0 || index !== _vm.datagood.length - 1 ? _c("span", {
            staticClass: "item_del",
            staticStyle: {
              padding: "5px",
              color: "white"
            }
          }, [_vm._v("删除")]) : _vm._e(), index == _vm.datagood.length - 1 && index !== 0 ? _c("span", {
            staticClass: "item_del",
            staticStyle: {
              padding: "5px"
            },
            on: {
              click: function click($event) {
                return _vm.handleDeleteSeries(index);
              }
            }
          }, [_vm._v("删除")]) : _vm._e()], 1)]) : _vm._e()];
        }
      };
    })], null, true)
  }), _c("a-button", {
    staticStyle: {
      width: "616px",
      color: "#3270f5"
    },
    on: {
      click: _vm.handleAddDatagood
    }
  }, [_vm._v("+ 添加一行")]), _c("br"), _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#969696"
    }
  }, [_vm._v(" 注：商品参数添加的内容必须为英文 ")])], 1), _c("a-form-item", {
    attrs: {
      label: "Color"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["color", {
        rules: [{
          required: true,
          message: "请输入颜色"
        }]
      }],
      expression: "[\n                        'color',\n                        {\n                            rules: [\n                                { required: true, message: '请输入颜色' },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      autocomplete: "off",
      placeholder: "请输入英文"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "搜索词"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["searchterm", {
        rules: [{
          required: true,
          message: "请输入搜索词"
        }]
      }],
      expression: "[\n                        'searchterm',\n                        {\n                            rules: [\n                                { required: true, message: '请输入搜索词' },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      autocomplete: "off",
      placeholder: "输入多个用逗号隔开"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "配送方式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["distributionid", {
        rules: [{
          required: true,
          message: "请选择物流模板"
        }]
      }],
      expression: "[\n                        'distributionid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择物流模板',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      placeholder: "请选择物流模板"
    }
  }, _vm._l(_vm.distributionidList.buyers, function (item) {
    return _c("a-select-option", {
      key: item.templateId
    }, [_vm._v(_vm._s(item.templateName))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "是否支持退换货"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isaftersale", {
        rules: [{
          required: true,
          message: "请选择"
        }],
        initialValue: 0
      }],
      expression: "[\n                        'isaftersale',\n                        {\n                            rules: [{ required: true, message: '请选择' }],\n                            initialValue: 0,\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      name: "radioGroup",
      placeholder: "请选择"
    }
  }, [_c("a-radio", {
    staticStyle: {
      color: "#3270f5"
    },
    attrs: {
      disabled: "",
      value: 1
    }
  }, [_vm._v("支持")]), _c("a-radio", {
    staticStyle: {
      color: "#3270f5"
    },
    attrs: {
      value: 0
    }
  }, [_vm._v("不支持")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("quill-editor", {
    ref: "myQuillEditor",
    staticClass: "editor",
    attrs: {
      options: _vm.editorOption
    },
    on: {
      blur: function blur($event) {
        return _vm.onEditorBlur($event);
      },
      focus: function focus($event) {
        return _vm.onEditorFocus($event);
      },
      ready: function ready($event) {
        return _vm.onEditorReady($event);
      },
      change: function change($event) {
        return _vm.onEditorChange($event);
      }
    },
    model: {
      value: _vm.productdescribe,
      callback: function callback($$v) {
        _vm.productdescribe = $$v;
      },
      expression: "productdescribe"
    }
  }), _c("form", {
    attrs: {
      action: "",
      method: "post",
      enctype: "multipart/form-data",
      id: "uploadFormMulti"
    }
  }, [_c("input", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: _vm.uniqueId,
      type: "file",
      name: "file",
      multiple: "",
      accept: "image/jpg, image/jpeg, image/png, image/gif"
    },
    on: {
      change: function change($event) {
        return _vm.handleUploadImg("uploadFormMulti");
      }
    }
  })])], 1), _c("a-form-item", {
    attrs: {
      label: "是否立即上架"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["status", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                        'status',\n                        {\n                            rules: [{ required: true, message: '请选择' }],\n                        },\n                    ]"
    }],
    attrs: {
      size: "large",
      disabled: _vm.isFormDisabled(),
      placeholder: "请选择！"
    }
  }, [_c("a-radio", {
    staticStyle: {
      color: "#3270f5"
    },
    attrs: {
      value: 0
    }
  }, [_vm._v("否   ")]), _c("a-radio", {
    staticStyle: {
      color: "#3270f5"
    },
    attrs: {
      value: 1
    }
  }, [_vm._v("是   ")])], 1)], 1), _c("div", {
    staticClass: "combinbtn"
  }, [_vm.addType != 1 ? _c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      width: "90px",
      height: "40px",
      background: "#f5f5f5",
      "border-radius": "6px",
      color: "#646464",
      border: "0",
      "margin-right": "26px"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd("back");
      }
    }
  }, [_vm._v("取消")]) : _vm._e(), _vm.addType == 1 ? _c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      "border-radius": "6px",
      color: "#3270f5",
      border: "1px solid #3270f5",
      "margin-right": "26px"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd("look");
      }
    }
  }, [_vm._v("预览")]) : _vm._e(), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["goods:add:submit"],
      expression: "['goods:add:submit']"
    }],
    staticStyle: {
      width: "90px",
      height: "40px",
      background: "#3270f5",
      "border-radius": "6px",
      color: "#ffffff",
      border: "0"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.btnName))])], 1)], 1), _vm.ishandleLook ? _c("div", [_c("a-modal", {
    staticClass: "showiframe",
    attrs: {
      centered: "",
      visible: _vm.ishandleLook,
      footer: null,
      closable: false
    },
    on: {
      cancel: _vm.setModal1Visible
    }
  }, [_c("div", {
    staticClass: "modalh5"
  }, [_c("iframe", {
    ref: "Iframe",
    staticClass: "iframestyle",
    attrs: {
      src: _vm.h5src
    }
  })])])], 1) : _vm._e()], 1), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleCancle
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };